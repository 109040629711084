(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/assets/javascripts/content-loader.js') >= 0) return;  svs.modules.push('/components/content/simple-article/assets/javascripts/content-loader.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
let imageSizes;
let jupiter;
let logger;
let videoContentLoader;
let youtubeVideoComponent;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  jupiter = trinidad.jupiter;
  logger = trinidad.logger('component:content:simple-article');
  imageSizes = trinidad.components.require('content', 'shared').imageSizes;
  videoContentLoader = trinidad.components.require('components', 'video-player');
  youtubeVideoComponent = trinidad.components.require('components', 'youtube-video');
} else {
  jupiter = {
    call: (options, callback) => svs.core.jupiter.call(options, data => callback(null, data), err => callback(err))
  };
  logger = new svs.core.log.Logger('component:content:simple-article');
  imageSizes = svs.content.shared.imageSizes;
  videoContentLoader = svs.components.video_player.contentLoader;
  youtubeVideoComponent = svs.components.youtube_video.utils;
}

const rollbackHeaderHierarchy = function () {
  let html = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let stepUp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const headerRegex = /<h([1-6])>(.*?)<\/h[1-6]>/gi;
  let h1Count = 0;
  if (html) {
    const modifiedHTML = html.replace(headerRegex, (match, tagLevel, content) => {
      const currentLevel = parseInt(tagLevel, 10);
      const newLevel = stepUp ? currentLevel + 1 : currentLevel - 1;
      if (newLevel === 1) {
        h1Count++;
      }
      if (h1Count > 1) {
        throw new Error('Only one <h1> tag is allowed.');
      }
      const newTagName = "h".concat(newLevel);
      const className = stepUp ? "class=\"h".concat(currentLevel, "\"") : '';
      return "<".concat(newTagName, " ").concat(className, ">").concat(content, "</").concat(newTagName, ">");
    });
    return modifiedHTML;
  }
};

function loadArticle(options, callback) {
  if (!options || !options.url) {
    callback('Required options parameters not supplied');
    return;
  }

  const jupiterOptions = {
    method: 'GET',
    path: options.url,
    req: options.req
  };
  try {
    jupiter.call(jupiterOptions, (err, data) => {
      if (err) {
        callback(err);
      } else if (!data.result[0]) {
        logger.debug('No article in data response', options.req);

        callback(null);
      } else {
        prepareArticles(data.result, options, (err, result) => callback(err, result[0]));
      }
    });
  } catch (e) {
    callback(e);
  }
}

function loadArticles(options, callback) {
  const {
    count
  } = options;
  if (typeof count === 'undefined') {
    logger.warn('Count parameter is missing.');
  }
  if (!options || !options.url) {
    callback('Required options parameters not supplied');
    return;
  }

  const jupiterOptions = {
    method: 'GET',
    path: options.url,
    req: options.req
  };
  try {
    jupiter.call(jupiterOptions, (err, data) => {
      if (err) {
        logger.warn("Failed to fetch articles, url: ".concat(options.url), options.req);
        callback("Failed to fetch articles:".concat(err.message));
      } else if (!data.result[0]) {
        callback(null, []);
      } else {
        prepareArticles(data.result, options, callback);
      }
    });
  } catch (e) {
    callback(e);
  }
}
function prepareImages(images) {
  return images.map(image => {
    image.sizes = imageSizes.getMediaQuery([imageSizes.ENUM.lg, imageSizes.ENUM.xs]);

    image.heightPercent = 560 / 1280 * 100;
    return image;
  });
}
function prepareVideos(videos) {
  return videos.map(video => {
    try {
      if (video.type === 'youtube') {
        let youtubeVid;
        youtubeVideoComponent.loadVideo({
          type: video.type,
          videoId: video.id,
          name: video.name,
          title: (video === null || video === void 0 ? void 0 : video.title) || 'Svenska Spel Video',
          duration: video === null || video === void 0 ? void 0 : video.duration,
          description: video === null || video === void 0 ? void 0 : video.description,
          publishedAt: video === null || video === void 0 ? void 0 : video.publishedAt
        }, (err, model) => {
          if (err) {
            logger.warn("Error loading youtube video: ".concat(err.toString()));
          } else {
            youtubeVid = model;
          }
        });
        return youtubeVid;
      } else if (video.type === 'solidtango') {
        let solidtangoVid;
        videoContentLoader.loadVideo({
          api: false,
          type: video.type,
          videoId: video.id,
          title: video.title,
          duration: video === null || video === void 0 ? void 0 : video.duration,
          description: video === null || video === void 0 ? void 0 : video.description,
          thumbnail: video === null || video === void 0 ? void 0 : video.thumbnail,
          publishedAt: video === null || video === void 0 ? void 0 : video.publishedAt
        }, (err, loadedVideo) => {
          if (err) {
            logger.warn("Error loading solidtango video content: ".concat(err.toString()));
          } else if (loadedVideo) {
            solidtangoVid = _objectSpread({
              name: video.name
            }, loadedVideo);
          }
        });
        return solidtangoVid;
      }
      throw new Error("Unsupported video type: ".concat(video.type));
    } catch (error) {
      logger.warn("Error preparing video: ".concat(error.toString()));
      return null; 
    }
  });
}

function prepareArticles(articles, options, callback) {
  const preparedArticles = articles.map(article => {
    var _options$req;
    const useH2ForTitle = (options === null || options === void 0 || (_options$req = options.req) === null || _options$req === void 0 || (_options$req = _options$req.route) === null || _options$req === void 0 || (_options$req = _options$req.moduleConfig) === null || _options$req === void 0 ? void 0 : _options$req.useH2ForTitle) || options.useH2ForTitle || article.useH2ForTitle || false;
    if (useH2ForTitle) {
      article.useH2ForTitle = true;
      article.body = rollbackHeaderHierarchy(article.body, true);
    }
    if (article.images) {
      article.images = prepareImages(article.images, options);
    }
    if (article.videos) {
      article.videos = prepareVideos(article.videos);
    }
    article.isInverted = options.isInverted;
    return article;
  });
  callback(null, preparedArticles);
}
if (isServer) {
  module.exports = {
    loadArticle,
    loadArticles,
    prepareArticles
  };
} else {
  svs.content.simple_article.contentLoader = {
    loadArticle,
    loadArticles,
    prepareArticles
  };
}

 })(window);