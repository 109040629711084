(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/assets/javascripts/helpers/handlebar-helpers.js') >= 0) return;  svs.modules.push('/components/content/simple-article/assets/javascripts/helpers/handlebar-helpers.js');
"use strict";


(function () {
  'use strict';

  function registerHelpers(hbs) {
    hbs.registerHelper('json', context => {
      const arr = JSON.stringify(context);
      return arr === undefined ? '[]' : arr;
    });
    hbs.registerHelper('hasText', function (text, options) {
      const fnTrue = options.fn;
      const fnFalse = options.inverse;
      return text && text !== '<p>&nbsp;</p>' ? fnTrue(this) : fnFalse(this);
    });
    hbs.registerHelper('addEllipsis', text => "".concat(text, "..."));
    hbs.registerHelper('boolean', text => Boolean(text));
    hbs.registerHelper('showSummarized', text => {
      const aCloseTag = /<\/a>/g;
      const aOpenTag = /<a href="(.*?)">/g;
      if (text && text !== '<p>&nbsp;</p>') {
        return "".concat(text.replace(aOpenTag, '').replace(aCloseTag, '').slice(0, 363), "...");
      }
    });
  }
  if (typeof exports === 'object') {
    registerHelpers(require('hbs'));
  } else {
    registerHelpers(Handlebars);
  }
})();

 })(window);