(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/views/simple-article-read-more.js') >= 0) return;  svs.modules.push('/components/content/simple-article/views/simple-article-read-more.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.simple_article=_svs.content.simple_article||{};
_svs.content.simple_article.templates=_svs.content.simple_article.templates||{};
svs.content.simple_article.templates.simple_article_read_more = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"url","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":16}}}) : helper)))
    + "\">Läs vidare</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a onclick=\"svs.content.simple_article.showSummary(event, '"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":67}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":71},"end":{"line":4,"column":77}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"areaName") || (depth0 != null ? lookupProperty(depth0,"areaName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"areaName","hash":{},"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":93}}}) : helper)))
    + "','"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":105}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":4,"column":109},"end":{"line":4,"column":117}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"ingress") || (depth0 != null ? lookupProperty(depth0,"ingress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ingress","hash":{},"data":data,"loc":{"start":{"line":4,"column":121},"end":{"line":4,"column":132}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":4,"column":136},"end":{"line":4,"column":149}}}) : helper)))
    + "', '"
    + alias4(((helper = (helper = lookupProperty(helpers,"modified") || (depth0 != null ? lookupProperty(depth0,"modified") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modified","hash":{},"data":data,"loc":{"start":{"line":4,"column":153},"end":{"line":4,"column":165}}}) : helper)))
    + "', "
    + alias4((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":4,"column":168},"end":{"line":4,"column":183}}}))
    + ", "
    + alias4((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"videos") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":4,"column":185},"end":{"line":4,"column":200}}}))
    + ", "
    + alias4((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"c2a") : depth0),{"name":"json","hash":{},"data":data,"loc":{"start":{"line":4,"column":202},"end":{"line":4,"column":214}}}))
    + ")\"\n   class=\"js-article-read-more article-read-more\"\n   data-track=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":23}}}) : helper)))
    + "\"\n>\n    Läs vidare\n</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-content-simple_article-simple_article_read_more'] = svs.content.simple_article.templates.simple_article_read_more;
})(svs, Handlebars);


 })(window);