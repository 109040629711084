(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/assets/javascripts/simple-article.js') >= 0) return;  svs.modules.push('/components/content/simple-article/assets/javascripts/simple-article.js');
"use strict";



function trackClickC2a(e) {
  const track = e.currentTarget.getAttribute('data-track');
  if (track) {
    const areaName = e.currentTarget.getAttribute('data-area-name') || 'unknown';
    const evt = {
      id: track,
      name: track,
      position: areaName
    };
    if (e.currentTarget.getAttribute('target') && e.currentTarget.getAttribute('target') !== '_self') {
      svs.components.analytics.trackPromoClick(evt);
      return true;
    }
    svs.components.analytics.trackPromoClick(evt, () => {
      document.location = e.currentTarget.getAttribute('href');
    });
    return false;
  }
}
function trackClickReadMore(e) {
  const evt = {
    action: 'Read more',
    category: 'Articles',
    opt_label: e.currentTarget.getAttribute('data-track')
  };
  svs.components.analytics.trackEvent(evt);
}
$('body').on('click', '.js-article', trackClickC2a);
$('body').on('click', '.js-article-read-more', trackClickReadMore);
$('.simple-article-inline-link').each(function () {
  $(this).children().last().addClass('inline-text');
});
function getPageWrapper(target) {
  if (isRightSide(target)) {
    return target.parents('.widget-area');
  }
  const firstEl = $('body').children().first();
  return firstEl.is('a') ? firstEl.next() : firstEl;
}
function isRightSide(target) {
  return target.parents('.product-base-tabs-content').length === 1;
}
svs.content.simple_article.showSummary = function (e, name, id, areaName, title, body, ingress, published, modified, images, videos, c2a) {
  const swiperOptions = {
    alwaysScroll: false,
    scrollbars: false,
    scrollingX: false,
    scrollingY: true,
    updateOnChanges: true,
    updateOnWindowResize: true,
    windowScrollingActiveFlag: 'modalScrolling'
  };

  $('.js-reveal-animation').addClass('reveal-animation-simple-article');
  if (svs.core.detect.formfactor.mobile()) {
    svs.components.swiper.disableBodyScroll();
  } else {
    $('body').addClass('simple-article-disable-body-scroll');
  }
  const target = $(e.currentTarget);
  svs.components.revealAnimation.setUseAutoScroll(false);
  svs.components.revealAnimation.runRevealAnimation(e, () => {
    const pageWrapper = getPageWrapper(target);

    if (images.length) {
      images[0].sizes = svs.content.shared.imageSizes.getMediaQuery([svs.content.shared.imageSizes.ENUM.wide]);
    }
    pageWrapper.append(svs.content.simple_article.templates.simple_article({
      areaName,
      body,
      c2a,
      closeBtn: true,
      id,
      images,
      ingress,
      isRightSide: isRightSide(target),
      modified,
      name,
      published,
      title,
      videos
    }));
    const articleEl = pageWrapper.find('.simple-article-overlay');
    const topContainer = document.querySelector('.js-fixed-top-content');
    const setTopSpacing = (container, el) => {
      el.setAttribute('style', "top:".concat(container.offsetHeight, "px"));
    };
    setTopSpacing(topContainer, articleEl[0]);
    if (window.ResizeObserver) {
      svs.content.simple_article.resizeObserver = new ResizeObserver(() => {
        setTopSpacing(topContainer, articleEl[0]);
      });
      svs.content.simple_article.resizeObserver.observe(document.querySelector('body'));
    }
    svs.components.swiper.init(articleEl, swiperOptions);
  });
};

svs.content.simple_article.hideSummary = function (e, containerEl, skipAnimation) {
  let simpleArticleOverlay;
  if (e) {
    simpleArticleOverlay = $(e.currentTarget).parents('.simple-article-overlay');
  } else if (containerEl) {
    simpleArticleOverlay = containerEl.find('.simple-article-overlay');
  } else {
    return;
  }
  if (svs.core.detect.formfactor.mobile()) {
    svs.components.swiper.enableBodyScroll();
  } else {
    $('body').removeClass('simple-article-disable-body-scroll');
  }
  if (skipAnimation) {
    simpleArticleOverlay.find('.js-swiper-container').css('overflow-y', 'hidden');
    simpleArticleOverlay.remove();
    $('.js-reveal-animation').removeClass('reveal-animation-simple-article');
  } else {
    simpleArticleOverlay.find('.js-swiper-container').css('overflow-y', 'hidden');
    svs.components.revealAnimation.prepareRevertRevealAnimation(() => {
      simpleArticleOverlay.remove();
      svs.content.simple_article.resizeObserver && svs.content.simple_article.resizeObserver.unobserve(document.querySelector('body'));
      svs.components.revealAnimation.revertRevealAnimation(() => {
        $('.js-reveal-animation').removeClass('reveal-animation-simple-article');
      });
    }, this);
  }
};

 })(window);