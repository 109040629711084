(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/assets/javascripts/helpers/media-injector-helper.js') >= 0) return;  svs.modules.push('/components/content/simple-article/assets/javascripts/helpers/media-injector-helper.js');

'use strict';

const isServer = typeof exports === 'object';
const hbs = isServer ? require('hbs') : Handlebars;
const hbsImage = () => isServer ? hbs.cache['components-content-image_v2-image'] : svs.content.image_v2.templates.image;
const hbsVideoPlayer = () => isServer ? hbs.cache['components-components-video_player-video_player'] : svs.components.video_player.templates.video_player;
const hbsYoutubeVideo = () => isServer ? hbs.cache['components-components-youtube_video-youtube_video'] : svs.components.youtube_video.templates.youtube_video;
const hbsAppLinks = () => isServer ? hbs.cache['components-content-simple_article-simple_article_app_links'] : svs.content.simple_article.templates.simple_article_app_links;
hbs.registerHelper('mediaInjector', function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let images = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let videos = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return text.replace(/<imagerelation name="(.*?)"><\/imagerelation>/g, (match, name) => {
    const image = images.filter(image => image.typeName === name)[0];
    return image ? hbsImage()(image) : '';
  }).replace(/<videorelation name="(.*?)"><\/videorelation>/g, (match, name) => {
    const video = (videos === null || videos === void 0 ? void 0 : videos.length) && videos.filter(video => (video === null || video === void 0 ? void 0 : video.name) === name)[0];
    let videoHtml = "<div class=\"responsive-image-wrapper simple-article-16-9-wrapper responsive-image-wrapper-fit\">";
    if ((video === null || video === void 0 ? void 0 : video.type) === 'youtube') {
      videoHtml += hbsYoutubeVideo()(video);
    } else if ((video === null || video === void 0 ? void 0 : video.type) === 'solidtango') {
      videoHtml += hbsVideoPlayer()(video);
    }
    videoHtml += "</div>";
    return video ? videoHtml : '';
  });
});
hbs.registerHelper('appsInjector', function () {
  let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const apps = [{
    name: 'spelbutik-tur',
    href: {
      ios: 'https://itunes.apple.com/se/app/svenska-spel/id1109311106?mt=8',
      android: 'https://cdn2.svenskaspel.net/apptb/spelbutiken-latest.apk'
    }
  }, {
    name: 'spelbutik-sport',
    href: {
      ios: 'https://itunes.apple.com/se/app/svenska-spel-sport-casino/id1442191692',
      android: 'https://cdn2.svenskaspel.net/applb/spelbutiken-latest.apk'
    }
  }, {
    name: 'oddset',
    href: {
      ios: 'https://itunes.apple.com/se/app/oddset/id1343695840?mt=8',
      android: 'https://cdn2.svenskaspel.net/app/oddset-android-latest.apk'
    }
  }, {
    name: 'poker',
    href: {
      ios: 'https://itunes.apple.com/se/app/svenska-spel-poker/id635820089?mt=8',
      android: 'http://download.poker.svenskaspel.se/update/bin/SVSNIX/smartphone-armv7.apk',
      androidTablet: 'http://download.poker.svenskaspel.se/update/bin/SVSNIX/tablet-armv7.apk'
    }
  }, {
    name: 'casino',
    href: {
      android: 'https://cdn2.svenskaspel.net/app/casino-android-latest.apk'
    }
  }];
  const appElements = text.match(/<applinks name="(.*?)"><\/applinks>/g);
  const appsToRender = [];
  if (appElements) {
    appElements.forEach(appElement => {
      const appName = appElement.replace(/<applinks name="/g, '').replace(/"><\/applinks>/g, '');
      return appsToRender.push(apps.filter(app => app.name === appName)[0]);
    });
    return appsToRender ? hbsAppLinks()(appsToRender) : '';
  }
});

 })(window);