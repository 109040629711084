(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/views/simple-article-close.js') >= 0) return;  svs.modules.push('/components/content/simple-article/views/simple-article-close.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.content=_svs.content||{};
_svs.content.simple_article=_svs.content.simple_article||{};
_svs.content.simple_article.templates=_svs.content.simple_article.templates||{};
svs.content.simple_article.templates.simple_article_close = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " btn-100 ";
},"3":function(container,depth0,helpers,partials,data) {
    return " btn-300";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<button class=\"btn";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":69}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " btn-circle btn-default btn-icon btn-icon-center simple-article-close-btn\"\n        onclick=\"svs.content.simple_article.hideSummary(event)\">\n  "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":"close","size":"300"},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":39}}}))
    + "\n</button>\n<div class=\"clearfix\"></div>";
},"useData":true});
Handlebars.partials['components-content-simple_article-simple_article_close'] = svs.content.simple_article.templates.simple_article_close;
})(svs, Handlebars);


 })(window);