(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/simple-article/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/content/simple-article/assets/javascripts/prescript.js');
var svs = svs || {};

(function(svs) {
  'use strict';

  svs.content = svs.content || {};
  svs.content.simple_article = svs.content.simple_article || {};
})(svs);


 })(window);